.base {
  width: 20rem;
  text-align: center;

  img {
    width: 12rem;
    height: 10rem;
  }

  h2 {
    margin: 0;
    left: 0;
  }

  p {
    margin: 0;
    left: 0;
  }
}
