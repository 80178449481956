.bannerImage {
  width: 100%;
  height: 20rem;
  overflow: hidden;

  background-image: url("../../public/images/Coffee-Banner.jpg");
  background-size: cover;
}

.navGrid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.link {
  color: black;
}

h1 {
  margin: 0;
}

.aboutSpacer {
  width: 40%;
  height: 1px;
  background-color: black;
  margin: 1rem 0 1rem 0;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    max-width: 70rem;
    margin: 0;
  }
}
