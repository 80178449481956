.padding {
  height: 4rem;
}

.title {
  margin: 0;
  text-align: center;
}

.locationContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .locationCard {
    flex-direction: column;
    text-align: center;
  }

  .lineSpace {
    height: 1px !important;
    width: 8rem !important;
    margin: 1rem 0 1rem 0 !important;
  }
}

.locationCard {
  display: flex;
  align-items: center;
  //height: 10rem;
}

.locationTitle {
  margin: 0;
  width: 10rem;
}

.locationLocation {
  margin: 0;
  max-width: 20rem;
}

.lineSpace {
  height: 8rem;
  width: 1px;
  background-color: black;
  margin: 0 1rem 0 1rem;
}

h3 {
  margin: 0;
}
