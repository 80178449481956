.base {
  width: 100%;
  position: absolute;
  z-index: 99;
}

.header {
  width: 100%;
  height: 4rem;
  background-color: rgba(20, 20, 20, 0.65);
  display: flex;
  justify-content: space-between;
}

.headerLogo {
  height: 100%;
}

.burgerMenuButton {
  height: calc(100% - 20px);
  transition-duration: 0.5s;
  transition-property: background-color;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  img {
    height: 100%;
  }
}

.burgerMenuButton:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.8);
}

$menu-width: 15rem;

.burgerMenu {
  background-color: rgb(73, 205, 204);
  opacity: 0;
  position: fixed;
  height: 100%;
  width: $menu-width;
  transform: translateX(-$menu-width);
  transition-property: transform, opacity;
  transition-duration: 0.5s;

  color: black;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

  a {
    color: black;
  }

  a:visited {
    color: black;
  }
}

.burgerMenuOpen {
  transform: translateX(0rem) !important;
  opacity: 0.8 !important;
}
