.ingredientIcon {
  height: 5rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ingredientContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 60rem;

  img {
    height: 10rem;
    border-radius: 3px;
  }

  p {
    text-align: center;
    font-size: 1.3rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.revIfMobile {

}

@media (max-width: 600px) {
  .ingredientContainer {
    flex-direction: column;

    img {
      display: none;
    }
  }

  .revIfMobile {
    flex-direction: column-reverse;
  }
}

.spacer {
  width: 1rem;
  height: 1px;
  background-color: grey;
  margin: 0 1rem 0 1rem;
}
