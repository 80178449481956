.ingredientIcon {
  height: 5rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;
}

.spacer {
  width: 1rem;
  height: 1px;
  background-color: grey;
  margin: 0 1rem 0 1rem;
}

.productContainer {
  max-width: 80rem;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.productCard {
  text-align: center;

  img {
    border-radius: 100%;
    width: 15rem;
    margin: 1rem 0 1rem 0;
  }
}
